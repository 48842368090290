body {
    font-family: 'Geo', sans-serif;
    font-size: 24px;
    /*background-color: #C5C1E2;*/
    background-image: url("img/cookie-scene/nightsky.png");
    background-color: #121737;
    /*background-image: linear-gradient(to bottom, #029FD2, #00698B);*/
    color: white;
    text-shadow: 2px 2px black;
    overflow-x: hidden;
    background-repeat: no-repeat;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

::-webkit-scrollbar {
    -webkit-appearance: none;
}

.ReactCollapse--collapse {
    transition: height 300ms;
}

button:hover {
    cursor: pointer !important;
}

.header-mobile {
    display: flex;
    flex-direction: column;
    padding: 0 5%;
    border-bottom: 3px solid #492726;
    background-color: #00000070;
    color: white;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 99999;
    transition: height 100ms ease;
    backdrop-filter: blur(4px);
}

.mobile-navlist {
    margin: 0;
    padding: 0;
    height: 80vh;
    width: 92vw;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.menu-1 {
    top: -35px;
    font-size: 50px;
    left: 10px
}

.mobile-navlist li {
    border-bottom: 1px solid #FFFFFF50;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 5%;
    border-bottom: 3px solid #492726;
    align-items: center;
    background-color: #00000070;
    color: white;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 999;
    transition: height 100ms ease;
    backdrop-filter: blur(4px);
}

.navtitle {
    font-size: 32px;
    margin-left: var(--space6);
}

.header ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0 0 0 0;
    margin: 0;
}

.header ul li {
    padding: var(--space2);
    text-align: center;
    cursor: pointer;
    border-bottom: 4px solid #00000050;
    transition: var(--transition1);
}

.header ul li:hover {
    border-bottom: 4px solid white;
    background-color: #00000010;
}

.connectwallet {
    margin-right: var(--space8);
    background-color: #00000010;
    padding: var(--space2) var(--space4);
    border-bottom: 4px solid #00000050;
    transition: var(--transition1);
    cursor: pointer;
}

.connectwallet:hover {
    background-color: #00698B90;
    border-bottom: 4px solid white;
}

.connectwallet-mobile {
    margin-right: var(--space1);
    padding: var(--space2) var(--space1) var(--space2) var(--space1);
    border-bottom: 4px solid #00000050;
    transition: var(--transition1);
    cursor: pointer;
}

.connectwallet-mobile:hover {
    background-color: #00698B90;
    border-bottom: 4px solid white;
}

.pointer {
    cursor: pointer;
}

h1 {
    font-family: 'Press Start 2P', cursive;
    margin: 0;
    line-height: 60px;
    text-shadow: 6px 6px black;
}

p {
    text-shadow: 2px 2px black;
}

h2 {
    font-family: 'Press Start 2P', cursive;
    margin: 0;
    text-shadow: 2px 2px black;
}

.biglabel {
    font-size: 84px;
    margin: 0 !important;
    text-shadow: 2px 2px black;
    font-weight: 600;
}

.bigsublabel {
    font-size: 32px;
    text-shadow: 2px 2px black;
    font-weight: 600;
}


h4 {
    font-size: 38px;
    margin: 0 !important;
    text-shadow: 2px 2px black;
}

.splashcointainer {
    min-height: 105vh;
    margin-top: -80px;
    padding: 0 8%;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: top left;
    border-bottom: 3px solid #003459;
}

.goats {
    /*width: 300px;*/
    /*height:500px*/
    bottom: 0;
    right: 5vw;
    width: 50vw
}

.ledge-1 {
    width: 100%;
    float: right;
}

.ledge-3 {
    width: 100%;
    float: right;
    transform: scaleX(-1);
}

.splashcard {
    width: 650px;
    z-index: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.roadmapcontainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 var(--space10) 0;
    position: relative;
    z-index: 999;
    /*width: 100vw;*/
}

.roadmapcard {
    background-color: #00000090;
    padding: var(--space5);
    border: 3px solid white;
    display: flex;
    flex-direction: column;
}

.progressbar-wrapper {
    height: var(--space3);
    width: 98%;
    background-color: #05E800;
    border: 4px solid #3E0302;
}

.progressbar {
    height: var(--space3);
    width: 100%;
    background-color: #05E800;
}

.highlight {
    display: flex;
    flex: 1;
    width: 100%;
    height: 4px;
    background-color: #04BD00;
    margin-top: -4px;
}

.progress-hborder {
    height: 4px;
    width: 100%;
    background-color: gray;
}

.progress-vborder {
    width: 4px !important;
    height: 34px;
    background-color: gray
}

.listlabel {
    font-family: 'Press Start 2P', cursive;
}

.listitem-title {
    font-size: 28px;
    margin-right: var(--space1);
    display: flex;
    flex-direction: row;
}

.listitem-title-mobile {
    font-size: 20px;
    margin-right: var(--space1);
    display: flex;
    flex-direction: row;
}

.listitem-date {
    color: lightgray;
    text-shadow: none;
    margin-left: var(--space1);
}

.checkbox {
    border: 3px solid white;
    width: 30px !important;
    height: 30px !important;
    background-color: #00000060;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.checkbox-inactive {
    border: 3px solid #FFFFFF60;
    width: 30px !important;
    height: 30px !important;
    background-color: #00000060;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.filledcheck {
    width: 30px;
    height: 30px;
    background-color: #05E800;
    position: relative;
}

.filledcheck .bottom {
    position: absolute;
    height: 3px;
    width: 30px;
    bottom: 0;
    background-color: #04BD00;
}

.filledcheck .right {
    position: absolute;
    height: 30px;
    width: 3px;
    right: 0;
    background-color: #04BD00;
}

.redcheck {
    width: 30px;
    height: 30px;
    background-color: #eb4034;
    position: relative;
}

.redcheck .bottom {
    position: absolute;
    height: 3px;
    width: 30px;
    bottom: 0;
    background-color: #d9392e;
}

.redcheck .right {
    position: absolute;
    height: 30px;
    width: 3px;
    right: 0;
    background-color: #c72c22;
}

.tennis {
    position: absolute;
    top: 0px;
    left: 0px;
}

.football {
    position: absolute;
    top: 60px;
    left: 180px;
}

.baseball {
    position: absolute;
    top: 260px;
    left: 80px;
}

.soccer {
    position: absolute;
    top: 430px;
    left: 180px;
}

.basketball {
    position: absolute;
    top: 630px;
    left: 20px;
}

.golf {
    position: absolute;
    top: 650px;
    left: 400px;
    height: 100px;
    width: 110px;
    transform: rotate(45deg);
}

.meetcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--space10) 10%;
}

.membertile {
    width: 250px !important;
    height: 250px !important;
    /*border: 3px solid white;*/
    /*margin: 0 var(--space4);*/
    background-size: cover;
    margin-left: -40px;
    margin-top: -24px;
    transition: 150ms ease;
}


/* Hover Shadow */
@keyframes hover {
    50% {
        transform: translateY(-3px);
    }
    100% {
        transform: translateY(-6px);
    }
}

@keyframes hover-shadow {
    0% {
        transform: translateY(9px);
        opacity: 0.4;
    }
    50% {
        transform: translateY(3px);
        opacity: 1;
    }
    100% {
        transform: translateY(9px);
        opacity: 0.4;
    }
}

.hover-shadow {
    display: inline-block;
    position: relative;
    transition-duration: 0.3s;
    transition-property: transform;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transform: translateZ(0);
    /*box-shadow: 0 0 1px rgba(0, 0, 0, 0);*/
}

.hover-shadow:before {
    pointer-events: none;
    position: absolute;
    z-index: -1;
    content: "";
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 0;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
    /* W3C */
    transition-duration: 0.3s;
    transition-property: transform opacity;
}

.hover-shadow:hover {
    transform: translateY(-6px);
    animation-name: hover;
    animation-duration: 1.5s;
    animation-delay: 0.3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    box-shadow: 6px 8px 0px #00000040;
}

.hover-shadow:hover:before {
    opacity: 0.4;
    transform: translateY(9px);
    animation-name: hover-shadow;
    animation-duration: 1.5s;
    animation-delay: 0.3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.shinetile {
    width: 250px !important;
    height: 250px !important;
    border: 3px solid white;
    margin: 0 var(--space4);
    background-size: cover;
    overflow: hidden;
}

.shinetilesm {
    width: 150px !important;
    height: 150px !important;
    border: 3px solid white;
    margin: 0 var(--space4);
    background-size: cover;
    overflow: hidden;
}

/* Shine */
.hover14 figure {
    position: relative;
}

.hover14 figure::before {
    position: absolute;
    /*top: -40px;*/
    left: -170%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg) scale(1.3);
}

.hover14 figure:hover::before {
    -webkit-animation: shine .75s;
    animation: shine .75s;
}

@-webkit-keyframes shine {
    100% {
        left: 140%;
    }
}

@keyframes shine {
    100% {
        left: 140%;
    }
}

.memberlabel {
    font-size: 20px;
    color: #1d2d44;
    text-shadow: none;
    color: #1D9BEF
}

.membersublabel {
    font-size: 20px;
    color: #1d2d44;
    text-shadow: none;
    color: lightgray;
}

.teamcontainer {
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    /*align-items: center;*/
    padding: var(--space10) 10% 0 10%;
    position: relative;
    z-index: 3;
    min-height: 1400px;
}

.yellow {
    color: #F2F60F
}

.faqcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--space10) 0;
    position: relative;
    z-index: 2;
}

/*.answerslist {*/
/*    padding: 0 -16px;*/
/*}*/

.answer {
    padding: 16px;
    border: 3px solid #492726;
    background: #00000080;
}

.faqlistitem {
    padding: var(--space1) var(--space2);
    border-bottom: 1px solid #1d2d4460;
    transition: var(--transition1);
    cursor: pointer;
}

.faqlistitem:hover {
    background-color: #00000020;
}

.chevron {
    width: 26px;
    height: 26px;
    transition: 300ms ease;
}

.footer {
    margin-top: -80px;
    height: 80px;
    display: flex;
    flex-direction: row-reverse;
    padding: 0 10%;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(to top, #00000090, #00000060);
    position: relative;
    z-index: 999;
}


button {
    background: none;
    border: 3px solid white;
    padding: var(--space1) var(--space2);
    font-family: 'Geo', sans-serif;
    font-size: 20px;
    color: white;
    cursor: pointer;
    transition: var(--transition1)
}

button:hover {
    transform: translateY(-2px);
}

.discord {
    cursor: pointer !important;
}

.discord:hover {
    background-color: #5766F2;
    border: 3px solid #323a8a;
}

.twitter {
    cursor: pointer;
}

.twitter:hover {
    background-color: #1D9BEF;
    border: 3px solid #115d8f;
}

.discord-icon {
    transition: var(--transition1);
}

.discord-icon:hover {
    color: #5766F2;
}

.twitter-icon {
    transition: var(--transition1);
}

.twitter-icon:hover {
    color: #70d6ff;
}

.trophy {
    width: 120px;
    margin-left: var(--space2);
    transform: rotate(-11deg);
}

.medal {
    width: 150px;
    margin-right: var(--space4);
    transform: rotate(5deg);
}


.btn {
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 2px solid #e74c3c;
    border-radius: 0.6em;
    color: #e74c3c;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-family: 'Geo', sans-serif;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.btn:hover, .btn:focus {
    color: #fff;
    outline: 0;
}

.mint-here {
    color: #70d6ff
}

.second {
    border-radius: 0;
    border-color: #1282a2;
    color: #fff;
    background-blend-mode: luminosity;
    background-image: -webkit-linear-gradient(left, #70d6ff, #70d6ff 5%, #1282a2 5%, #1282a2 10%, #70d6ff 10%, #70d6ff 15%, #1282a2 15%, #1282a2 20%, #70d6ff 20%, #70d6ff 25%, #1282a2 25%, #1282a2 30%, #70d6ff 30%, #70d6ff 35%, #1282a2 35%, #1282a2 40%, #70d6ff 40%, #70d6ff 45%, #1282a2 45%, #1282a2 50%, #70d6ff 50%, #70d6ff 55%, #1282a2 55%, #1282a2 60%, #70d6ff 60%, #70d6ff 65%, #1282a2 65%, #1282a2 70%, #70d6ff 70%, #70d6ff 75%, #1282a2 75%, #1282a2 80%, #70d6ff 80%, #70d6ff 85%, #1282a2 85%, #1282a2 90%, #70d6ff 90%, #70d6ff 95%, #1282a2 95%, #1282a2 100%);
    background-image: linear-gradient(to right, #70d6ff, #70d6ff 5%, #1282a2 5%, #1282a2 10%, #70d6ff 10%, #70d6ff 15%, #1282a2 15%, #1282a2 20%, #70d6ff 20%, #70d6ff 25%, #1282a2 25%, #1282a2 30%, #70d6ff 30%, #70d6ff 35%, #1282a2 35%, #1282a2 40%, #70d6ff 40%, #70d6ff 45%, #1282a2 45%, #1282a2 50%, #70d6ff 50%, #70d6ff 55%, #1282a2 55%, #1282a2 60%, #70d6ff 60%, #70d6ff 65%, #1282a2 65%, #1282a2 70%, #70d6ff 70%, #70d6ff 75%, #1282a2 75%, #1282a2 80%, #70d6ff 80%, #70d6ff 85%, #1282a2 85%, #1282a2 90%, #70d6ff 90%, #70d6ff 95%, #1282a2 95%, #1282a2 100%);
    background-position: 0 0;
    background-size: 100%;
    -webkit-transition: background 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    font-family: 'Geo', sans-serif;
    font-size: 25px;
    text-shadow: 2px 2px black;
}

.second:hover {
    background-position: 100px;
}


.splashsectioncointainer {
    display: flex;
    /*align-items: center;*/
    justify-content: center;
    text-align: center;
    /*min-height: 180vh;*/
    z-index: 1;
}

.splash2bg {
    background-image: url("img/misc/woodwallp.png");
    background-size: cover;
}

.splash2bg2 {
    background-image: url("img/misc/woodwallp.png");
}

.teambg {
    background-image: url("img/wires-scene/teambg.png");
    background-size: cover;
}

.curtainbg {
    background-image: url("img/stage-scene/curtainsbg.png");
}


.splashsection2cointainer {
    display: flex;
    /*align-items: center;*/
    /*justify-content: center;*/
    min-height: 1400px;
    z-index: 1;
    padding: var(--space10) 10%;
}

.meetsectioncontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--space10) 10%;
    z-index: 2;
    position: relative;
    background-color: black;
}

.legdewrapper {
    flex: 1;
    min-width: 500px;
    position: relative;
}

.legdewrapper img {
    position: absolute;
    top: 50%
}

.mtnslayer {
    position: absolute;
    bottom: -60vh;
    width: 100%;
    z-index: 1;
}

.mtnslayer2 {
    position: absolute;
    bottom: -90vh;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    width: 100%;
    z-index: 1;
}

.mtnslayer3 {
    position: absolute;
    bottom: -130vh;
    width: 100%;
    z-index: 1;
}

.mtnslayer4 {
    position: absolute;
    bottom: -180vh;
    width: 100%;
    z-index: 1;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.mtnslayer5 {
    position: absolute;
    bottom: -210vh;
    width: 100%;
    z-index: 1;
}

.mtnslayer6 {
    position: absolute;
    bottom: -220vh;
    width: 100%;
    z-index: 1;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.mtnslayer7 {
    position: absolute;
    bottom: -540vh;
    width: 100%;
    z-index: 1;
}

.mtnslayer8 {
    position: absolute;
    bottom: -590vh;
    width: 100%;
    z-index: 1;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.mtnslayer9 {
    position: absolute;
    bottom: -260vh;
    width: 100%;
    z-index: 1;
}

.mtnslayer10 {
    position: absolute;
    bottom: -290vh;
    width: 100%;
    z-index: 1;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.mtnslayer11 {
    position: absolute;
    bottom: -315vh;
    width: 100%;
    z-index: 1;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.mtnslayer12 {
    position: absolute;
    bottom: -340vh;
    width: 100%;
    z-index: 1;
}

.hill3 {
    position: absolute;
    bottom: -380vh;
    width: 100%;
    z-index: 1;
}

.water1 {
    position: absolute;
    bottom: -410vh;
    width: 100%;
    z-index: 1;
}

.sand2 {
    position: absolute;
    bottom: -410vh;
    width: 100%;
    z-index: 1;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.water2 {
    position: absolute;
    bottom: -450vh;
    width: 100%;
    z-index: 1;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.water3 {
    position: absolute;
    bottom: -480vh;
    width: 100%;
    z-index: 1;
}

.water4 {
    position: absolute;
    bottom: -500vh;
    width: 100%;
    z-index: 1;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.waterraft {
    position: absolute;
    bottom: -540vh;
    width: 100%;
    z-index: 1;
}

.water5 {
    position: absolute;
    bottom: -570vh;
    width: 100%;
    z-index: 1;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.water6 {
    position: absolute;
    bottom: -600vh;
    width: 100%;
    z-index: 1;
}

.water7 {
    position: absolute;
    bottom: -620vh;
    width: 100%;
    z-index: 1;
}

.cloud1 {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 0;
    opacity: .5;
}

.cloud2 {
    position: absolute;
    left: 0;
    right: -400px;
    top: 35px;
    z-index: 0;
    opacity: .6;
}

.cloud3 {
    position: absolute;
    left: 0;
    right: 0;
    top: 535px;
    z-index: 0;
    opacity: .6;
}

.cloud4 {
    position: absolute;
    left: 0;
    right: 0;
    top: 205px;
    z-index: 0;
    opacity: .6;
}

.cloudimage {
    transform: scale(.8);
}

.cloudimagem {
    transform: scale(.5);
}

.cloudimage2 {
    transform: scale(.6);
}


.locked-section {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mobile-roadmap {
    padding: var(--space4);
    background-image: url("img/misc/woodwallp.png");
    background-size: cover;
}

.locked-header {
    font-size: 50px;
}

.locked-desc {
    font-size: 24px;
    margin: var(--space2) 0 0 0;
}

.mobile-name {
    font-size: 18px;
}

.mobile-subname {
    font-size: 16px;
    text-shadow: none;
    color: #1D9BEF
}

.mobile-nickname {
    font-size: 16px;
}

.pulse {
    animation: crescendo 1s alternate infinite ease-in;
}

.linear-wipe {
    text-align: center;
    background: linear-gradient(to right, #FFF 20%, #FF0 40%, #FF0 60%, #FFF 80%);
    background-size: 200% auto;
    color: #000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 2s linear infinite;
    text-shadow: none !important;
}

@keyframes shine {
    to {
        background-position: 200% center;
    }
}


@keyframes crescendo {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.07);
    }
}

.mint-page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 100ms ease;
    background-color: #000000D6;
    z-index: 3;
    backdrop-filter: blur(10px);
}

.mint-close {
    position: absolute;
    top: 64px;
    right: 64px;
    cursor: pointer;
    transition: 100ms ease;
}

.mint-close:hover {
    transform: translateY(-6px);
    color: #eb4034
}

.mint-close-mobile {
    transition: 100ms ease;
}

.staking-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    background-image: url("img/misc/woodwallp.png");
    background-size: cover;
    border: 5px solid saddlebrown;
}

.staking-header {
    background-color: #00000075;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--space4);
    width: 100%;
}

.staking-container-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.muffin-item {
    background-color: #00000040;
    padding: var(--space2);
    backdrop-filter: blur(4px);
}

.press-start {
    font-family: 'Press Start 2P', cursive !important;
    font-size: 14px;
}

.shelf-title {
    font-size: 20px;
    font-family: 'Press Start 2P', cursive;
    margin-bottom: var(--space2);
}

.shelf-stat {
    padding: var(--space2) var(--space2) var(--space5) var(--space2);
}

.modal-stat {
    display: flex;
    flex-direction: column;
    padding: var(--space1) var(--space2);

}

.shelf-stat-label {
    font-size: 18px;
    color: #FFFFFFD0
}

.overview-stat-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*background-image: url("img/misc/woodwallp.png");*/
    /*background-size: cover;*/
    background-position: top left;
    border: 5px solid #003459;
    margin: 0 var(--space2)
}

.tile-header {
    background-color: #00000075;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--space2) 0;
    width: 100%;;
}

.tile-stat {
    font-family: 'Press Start 2P', cursive !important;
    font-size: 48px;
    padding: var(--space4)
}

.tile-stat-sm {
    font-family: 'Press Start 2P', cursive !important;
    font-size: 36px;
}

.Modal {
    /*position: relative;*/
    /*z-index: 99999;*/
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #00000080;
    z-index: 9999999;
}

.modal-body {
    background-color: #1d2d44C6;
    padding: var(--space2);
    border: 4px solid #323a8a;
    backdrop-filter: blur(4px);
}

.modal-muffin {
    width: 300px;
    height: 300px;
}

.muffin-stat-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 500px;
}

.modal-main-stats {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: var(--space4) !important;
    background-color: #00000080;
    padding: var(--space1);
}

.close-modal-x {
    cursor: pointer;
    position: absolute;
    top: var(--space2);
    right: var(--space2);
}

input {
    font-family: Geo;
    background-color: #00000080;
    border: 2px solid #323a8a;
    outline: none !important;
    font-size: 28px;
    font-weight: 500;
    padding: var(--space05) var(--space1);
    color: #FFFFFF;
    width: 100%;
    transition: 150ms ease;
}

input:focus {
    border: 2px solid #1D9BEF;
}

.quiz-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    background-image: url("img/misc/woodwallp.png");
    background-size: cover;
    border: 5px solid saddlebrown;
}

.cypher-overview {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    /*background-image: url("img/misc/woodwallp.png");*/
    /*background-size: cover;*/
    background-position: top left;
    border: 5px solid #003459;
    padding: var(--space2);
    background-color: #00000080;
}

.mint-price {
    font-size: 26px;
    margin-bottom: 32px;
}

.wallet-option {
    background-color: #0000001a;
    padding: 16px;
    text-align: center;
    margin: 32px 16px;
    padding: 16px;
    cursor: pointer;
    border: 2px solid transparent;
    transition: 100ms ease;
}

.wallet-option:hover {
    border: 2px solid #1D9BEF;
    transform: translateY(-3px)
}

.counter-button {
    cursor: pointer;
    padding: 0px 8px;
}

.mint-button {
    border-color: #1D9BEF;
    color: #1D9BEF;
    font-weight: bold;
}

.mint-button:hover {
    border: 2px solid #1D9BEF;
}

.contract-container {
    background-color: #1D9BEF20;
    border: 1px solid #1D9BEF;
    padding: 8px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: auto !important;
    margin-left: 0 !important;
    text-decoration: none;
}

.contract-wrapper {
    position: absolute;
    width: 200px;
    top: 48px;
    right: 164px;
}

.contract-wrapper-mobile {
    position: absolute;
    width: 150px;
    top: 64px;
    right: 24px;
}

.question-mark {
    top:40px;
    right:46%;
    font-size: 64px;
}
.walletconnect-modal__base {
    text-shadow: none !important;
    color:black !important;
}

.walletconnect-qrcode__text {
    text-shadow: none !important;
}
.walletconnect-connect__buttons__wrapper p {
    text-shadow: none !important;
}
