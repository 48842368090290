:root {
    --space05: 4px;
    --space1: 8px;
    --space2: 16px;
    --space3: 24px;
    --space4: 32px;
    --space5: 40px;
    --space6: 48px;
    --space7: 56px;
    --space8: 64px;
    --space9: 72px;
    --space10: 80px;
    --transition1: 150ms ease;
}

.transition1 {
    transition: var(--transition1);
}

.float-right {
    float: right !important
}

.text-center {
    text-align: center !important
}

.text-right {
    text-align: right !important
}

.relative {
    position: relative !important;
}

.absolute {
    position: absolute !important;
}

.wrap {
    flex-wrap: wrap !important;
}

.bg-cover {
    background-size: cover !important;
}

.p-0 {
    padding: 0 !important;
}

.p-05 {
    padding: var(--space05) !important;
}

.p-1 {
    padding: var(--space1) !important;
}

.p-2 {
    padding: var(--space2) !important;
}

.p-3 {
    padding: var(--space3) !important;
}

.p-4 {
    padding: var(--space4) !important;
}

.p-5 {
    padding: var(--space5) !important;
}

.p-6 {
    padding: var(--space6) !important;
}

.p-7 {
    padding: var(--space7) !important;
}

.p-8 {
    padding: var(--space8) !important;
}

.p-9 {
    padding: var(--space9) !important;
}

.p-10 {
    padding: var(--space10) !important;
}


.pb-0 {
    padding-bottom: 0 !important;
}

.pb-05 {
    padding-bottom: var(--space05) !important;
}

.pb-1 {
    padding-bottom: var(--space1) !important;
}

.pb-2 {
    padding-bottom: var(--space2) !important;
}

.pb-3 {
    padding-bottom: var(--space3) !important;
}

.pb-4 {
    padding-bottom: var(--space4) !important;
}

.pb-5 {
    padding-bottom: var(--space5) !important;
}

.pb-6 {
    padding-bottom: var(--space6) !important;
}

.pb-7 {
    padding-bottom: var(--space7) !important;
}

.pb-8 {
    padding-bottom: var(--space8) !important;
}

.pb-9 {
    padding-bottom: var(--space9) !important;
}

.pb-10 {
    padding-bottom: var(--space10) !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pt-05 {
    padding-top: var(--space05) !important;
}

.pt-1 {
    padding-top: var(--space1) !important;
}

.pt-2 {
    padding-top: var(--space2) !important;
}

.pt-3 {
    padding-top: var(--space3) !important;
}

.pt-4 {
    padding-top: var(--space4) !important;
}

.pt-5 {
    padding-top: var(--space5) !important;
}

.pt-6 {
    padding-top: var(--space6) !important;
}

.pt-7 {
    padding-top: var(--space7) !important;
}

.pt-8 {
    padding-top: var(--space8) !important;
}

.pt-9 {
    padding-top: var(--space9) !important;
}

.pt-10 {
    padding-top: var(--space10) !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-05 {
    padding-right: var(--space05) !important;
}

.pr-1 {
    padding-right: var(--space1) !important;
}

.pr-2 {
    padding-right: var(--space2) !important;
}

.pr-3 {
    padding-right: var(--space3) !important;
}

.pr-4 {
    padding-right: var(--space4) !important;
}

.pr-5 {
    padding-right: var(--space5) !important;
}

.pr-6 {
    padding-right: var(--space6) !important;
}

.pr-7 {
    padding-right: var(--space7) !important;
}

.pr-8 {
    padding-right: var(--space8) !important;
}

.pr-9 {
    padding-right: var(--space9) !important;
}

.pr-10 {
    padding-right: var(--space10) !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-05 {
    padding-left: var(--space05) !important;
}

.pl-1 {
    padding-left: var(--space1) !important;
}

.pl-2 {
    padding-left: var(--space2) !important;
}

.pl-3 {
    padding-left: var(--space3) !important;
}

.pl-4 {
    padding-left: var(--space4) !important;
}

.pl-5 {
    padding-left: var(--space5) !important;
}

.pl-6 {
    padding-left: var(--space6) !important;
}

.pl-7 {
    padding-left: var(--space7) !important;
}

.pl-8 {
    padding-left: var(--space8) !important;
}

.pl-9 {
    padding-left: var(--space9) !important;
}

.pl-10 {
    padding-left: var(--space10) !important;
}

.m-0 {
    margin: 0 !important;
}

.mb-a {
    margin-bottom: auto !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-05 {
    margin-bottom: var(--space05) !important;
}

.mb-1 {
    margin-bottom: var(--space1) !important;
}

.mb-2 {
    margin-bottom: var(--space2) !important;
}

.mb-3 {
    margin-bottom: var(--space3) !important;
}

.mb-4 {
    margin-bottom: var(--space4) !important;
}

.mb-5 {
    margin-bottom: var(--space5) !important;
}

.mb-6 {
    margin-bottom: var(--space6) !important;
}

.mb-7 {
    margin-bottom: var(--space7) !important;
}

.mb-8 {
    margin-bottom: var(--space8) !important;
}

.mb-9 {
    margin-bottom: var(--space9) !important;
}

.mb-10 {
    margin-bottom: var(--space10) !important;
}

.mt-0 {
    margin-top: 0 !important;
}

mt-a {
    margin-top: auto !important;
}

.mt-05 {
    margin-top: var(--space05) !important;
}

.mt-1 {
    margin-top: var(--space1) !important;
}

.mt-2 {
    margin-top: var(--space2) !important;
}

.mt-3 {
    margin-top: var(--space3) !important;
}

.mt-4 {
    margin-top: var(--space4) !important;
}

.mt-5 {
    margin-top: var(--space5) !important;
}

.mt-6 {
    margin-top: var(--space6) !important;
}

.mt-7 {
    margin-top: var(--space7) !important;
}

.mt-8 {
    margin-top: var(--space8) !important;
}

.mt-9 {
    margin-top: var(--space9) !important;
}

.mt-10 {
    margin-top: var(--space10) !important;
}

.mr-a {
    margin-right: auto !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-05 {
    margin-right: var(--space05) !important;
}

.mr-1 {
    margin-right: var(--space1) !important;
}

.mr-2 {
    margin-right: var(--space2) !important;
}

.mr-3 {
    margin-right: var(--space3) !important;
}

.mr-4 {
    margin-right: var(--space4) !important;
}

.mr-5 {
    margin-right: var(--space5) !important;
}

.mr-6 {
    margin-right: var(--space6) !important;
}

.mr-7 {
    margin-right: var(--space7) !important;
}

.mr-8 {
    margin-right: var(--space8) !important;
}

.mr-9 {
    margin-right: var(--space9) !important;
}

.mr-10 {
    margin-right: var(--space10) !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-a {
    margin-left: auto !important;
}

.ml-05 {
    margin-left: var(--space05) !important;
}

.ml-1 {
    margin-left: var(--space1) !important;
}

.ml-2 {
    margin-left: var(--space2) !important;
}

.ml-3 {
    margin-left: var(--space3) !important;
}

.ml-4 {
    margin-left: var(--space4) !important;
}

.ml-5 {
    margin-left: var(--space5) !important;
}

.ml-6 {
    margin-left: var(--space6) !important;
}

.ml-7 {
    margin-left: var(--space7) !important;
}

.ml-8 {
    margin-left: var(--space8) !important;
}

.ml-9 {
    margin-left: var(--space9) !important;
}

.ml-10 {
    margin-left: var(--space10) !important;
}

.br-05 {
    border-radius: var(--space05) !important;
}

.br-1 {
    border-radius: var(--space1) !important;
}

.br-10 {
    border-radius: var(--space10) !important;
}

.br-50 {
    border-radius: 50% !important;
}

.h-100 {
    height: 100%;
}

.w-40 {
    width: 40% !important;
}

.w-50 {
    width: 50% !important;
}

.w-60 {
    width: 50% !important;
}

.w-70 {
    width: 60% !important;
}

.w-80 {
    width: 80% !important;
}

.w-100 {
    width: 100% !important;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.col-je {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.col-jc {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.col-ac {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.col-ae {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}


.col-jb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.col-ac-jc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.row-plain {
    display: flex;
    flex-direction: row;
}

.col-plain {
    display: flex;
    flex-direction: column;
}

.row-ac {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.row-ac-jc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.row-ac-je {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.row-ac-ja {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.row-ac-jb {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.row-ae-jb {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.row-ae {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.i-xs {
    width: var(--space05);
    height: var(--space05);
    transition: var(--transition1);
}

.i-sm {
    width: var(--space1);
    height: var(--space1);
    transition: var(--transition1);
}

.i-md {
    width: var(--space2) !important;
    height: var(--space2) !important;
    transition: var(--transition1) !important;
}

.i-xm {
    width: 20px !important;
    height: 20px !important;
    transition: var(--transition1) !important;
}

.i-lg {
    width: var(--space3) !important;
    height: var(--space3) !important;
    transition: var(--transition1) !important;
}

.i-xl {
    width: var(--space4);
    height: var(--space4);
    transition: var(--transition1);
}

.shadow-1 {
    box-shadow: 0px 2px 20px rgba(53, 77, 152, 0.1);
    border-radius: 4px;
}
